<template>
  <div
    v-if="!isPast"
    class="d-flex"
    :class="{
      [active.status]: true,
    }"
    @contextmenu="
      $emit('open-context-menu', [
        $event,
        {
          context,
          entry,
        },
      ])
    "
  >
    <Status :status="active.status" />
    <span class="user-select-none count">{{ entryIndex + 1 }}.</span>
    <b-form-input
      v-if="showInput"
      :value="display()"
      class="drag-zone-empty transition-highlight"
      :class="{
        highlight: active.id === highlight,
      }"
      size="sm"
      :debounce="500"
      @update="
        $emit('change-service-provider-name', {
          value: $event || null,
          context,
          entry,
        })
      "
    />
    <div
      v-else
      class="d-flex align-items-center transition-highlight"
      :class="{
        [active.status]: true,
        highlight: active.id === highlight,
      }"
    >
      <span v-if="active.serviceProviderName">
        {{ active.serviceProviderName }}
      </span>
      <i v-else>{{ $t('StaffScheduling.UnknownEmployee') }}</i>
    </div>
  </div>
  <div v-else class="d-flex min-height">
    <Status :status="active.status" />
    <span class="user-select-none count">{{ entryIndex + 1 }}.</span>
    <div
      class="d-flex align-items-center transition-highlight"
      :class="{
        [active.status]: true,
        highlight: active.id === highlight,
      }"
    >
      <span v-if="active.serviceProviderName">
        {{ active.serviceProviderName }}
      </span>
      <i v-else>{{ $t('StaffScheduling.UnknownEmployee') }}</i>
    </div>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import moment from 'moment'
import Status from '../content/status.vue'
import { StaffSchedulingStatus } from '../../enums'

export default {
  components: {
    Status,
    BFormInput,
  },

  props: {
    entryIndex: {
      type: Number,
      required: true,
    },
    context: {
      type: Object,
      required: true,
    },
    active: {
      type: Object,
      required: true,
    },
    entry: {
      type: Object,
      required: true,
    },
    highlight: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    isPast() {
      const today = moment().subtract(1, 'day')
      return today.isAfter(moment(this.active.date, 'YYYY-MM-DD'))
    },
    showInput() {
      return (
        this.isPast === false &&
        this.active.status === StaffSchedulingStatus.transmitted
      )
    },
  },

  methods: {
    display() {
      return this.active.serviceProviderName || null
    },
  },
}
</script>

<style lang="scss" scoped>
.count {
  min-width: 1rem;
  align-self: center;
}

.drag-zone-empty {
  flex: 1;
  border: 1px var(--secondary) solid;
  padding-inline: 7px;
  border-radius: 1rem;
  transition: all 500ms ease-in-out;

  &:focus {
    outline: none;
    border-color: var(--primary);
  }
}

.min-height {
  min-height: 30px;
}

.canceled,
.rejected {
  span:last-child {
    text-decoration: line-through var(--danger) 1px;
  }
}

.transition-highlight {
  position: relative;

  &::after {
    transition: 500ms ease-in-out all;
    content: '';
    display: block;
    width: 0%;
    position: absolute;
    height: 2px;
    background-color: var(--danger);
    bottom: 0;
  }
}

.highlight {
  &::after {
    width: 100%;
  }
}
</style>
