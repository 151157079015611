<template>
  <b-row class="mt-2 d-flex justify-content-end">
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-secondary"
      class="mr-2"
      :disabled="!canUpdate"
      @click="$emit('update-calendar')"
    >
      {{ $t('Global.Save') }}
    </b-button>
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      variant="primary"
      @click="$emit('approve-calendar')"
    >
      {{ $t('Global.All') }} {{ $t('StaffScheduling.StatusAction.approved') }}
    </b-button>
  </b-row>
</template>

<script>
import { BRow, BButton } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BButton,
  },

  props: {
    canUpdate: {
      type: Boolean,
      required: true,
    },
    allApproved: {
      type: Boolean,
      required: true,
    },
    activeLayerIndex: {
      type: Number,
      default: () => null,
    },
    content: {
      type: Array,
      required: true,
    },
  },

  computed: {
    layers() {
      return this.content.map(x => x.layer)
    },
  },

  methods: {
    changeLayer(event) {
      this.$emit(
        'set-active-layer-index',
        this.layers.findIndex(x => x.id === event.id),
      )
    },
  },
}
</script>
