<template>
  <div>
    <Calendar :calendar="calendar" :date="timestamp">
      <template #frontRow="{ rowIndex }">
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].name }}
        </div>
        <div v-if="config.layers[rowIndex]">
          {{ config.layers[rowIndex].startTime }}
          -
          {{ config.layers[rowIndex].endTime }}
        </div>
      </template>
      <template v-slot="{ context }">
        <div
          v-if="
            context.indexConfig.groupIndex !== null &&
            config.groups[context.indexConfig.groupIndex]
          "
        >
          <div
            v-b-tooltip.hover.top="
              config.groups[context.indexConfig.groupIndex].name
            "
            class="status-bar"
            :style="{
              backgroundColor:
                config.groups[context.indexConfig.groupIndex].color,
            }"
          />
          <div class="entries">
            <div
              v-for="(entry, index) in context.value"
              :key="entry.insertedIndex"
            >
              <Item
                :entry-index="index"
                :entry="entry"
                :active="findActiveValues(context, entry)"
                :context="context"
                :highlight="highlight"
                @change-service-provider-name="changeServiceProviderName"
                @open-context-menu="openContextMenu(...$event)"
              />
            </div>
          </div>
        </div>
        <div v-else />
      </template>
    </Calendar>
    <ContextMenu
      ref="context-menu"
      @handle-status="handleStatus"
      @handle-post-edit="handlePostEdit"
    />
    <PostEditName
      ref="post-edit-name"
      @change-name="changeServiceProviderName"
    />
  </div>
</template>

<script>
import Calendar from '../content/calendar.vue'
import ContextMenu from './contextMenu.vue'
import Item from './item.vue'
import {
  displayStatus,
  insertContent,
  handleStatus,
  changeServiceProviderName,
} from '../../utility/handleDragDrop'
import { StaffSchedulingStatus } from '../../enums'
import PostEditName from './post-edit-name.vue'

export default {
  components: {
    ContextMenu,
    Calendar,
    Item,
    PostEditName,
  },

  props: {
    timestamp: {
      type: Number,
      required: true,
    },
    serviceProvider: {
      type: Object,
      required: true,
    },
    content: {
      type: Array,
      required: true,
    },
    activeLayerIndex: {
      type: Number,
      required: true,
    },
    updatePool: {
      type: Array,
      required: true,
    },
    highlight: {
      type: String,
      default: () => null,
    },
  },

  computed: {
    layers() {
      return this.content.map(x => x.layer)
    },
    calendar() {
      return this.content[this.activeLayerIndex].calendar
    },
    config() {
      return this.content[this.activeLayerIndex].layer.configuration
    },
  },

  methods: {
    updateName(value, context, entry) {
      this.changeServiceProviderName({ entry, context, value })
    },

    openContextMenu(event, { context, entry }) {
      const active = this.findActiveValues(context, entry)

      if (active.type === null) return

      const canShowCancellationNoted =
        active.status === StaffSchedulingStatus.canceled

      const validName =
        typeof active.serviceProviderName === 'string' &&
        active.serviceProviderName.trim() !== ''

      const canShowApproved =
        active.status === StaffSchedulingStatus.transmitted && validName

      const canShowEditName = active.status === StaffSchedulingStatus.approved

      if (
        canShowApproved === false &&
        canShowCancellationNoted === false &&
        canShowEditName === false
      ) {
        return
      }

      event.preventDefault()
      this.$refs['context-menu'].open(event, { context, entry, active })
    },

    handlePostEdit({ event, context }) {
      this.$refs['post-edit-name'].show(context, event.target)
    },

    findActiveValues(context, entry) {
      const updatePool = this.updatePool.find(x => x.id === entry.id)
      if (updatePool) return updatePool
      return entry
    },

    changeServiceProviderName(...args) {
      return changeServiceProviderName.call(this, ...args)
    },
    displayStatus(...args) {
      return displayStatus.call(this, ...args)
    },
    handleStatus(...args) {
      return handleStatus.call(this, ...args)
    },
    insertContent(...args) {
      return insertContent.call(this, ...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.status-bar {
  width: 100%;
  height: 20px;
  position: relative;
  background-color: var(--bg-color);
  &::after {
    content: '';
    display: block;
    width: 1px;
    height: 20px;
    background-color: var(--bg-color);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
  }
}

.entries {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
}
</style>
