var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Calendar',{attrs:{"calendar":_vm.calendar,"date":_vm.timestamp},scopedSlots:_vm._u([{key:"frontRow",fn:function(ref){
var rowIndex = ref.rowIndex;
return [(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].name)+" ")]):_vm._e(),(_vm.config.layers[rowIndex])?_c('div',[_vm._v(" "+_vm._s(_vm.config.layers[rowIndex].startTime)+" - "+_vm._s(_vm.config.layers[rowIndex].endTime)+" ")]):_vm._e()]}},{key:"default",fn:function(ref){
var context = ref.context;
return [(
          context.indexConfig.groupIndex !== null &&
          _vm.config.groups[context.indexConfig.groupIndex]
        )?_c('div',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
            _vm.config.groups[context.indexConfig.groupIndex].name
          ),expression:"\n            config.groups[context.indexConfig.groupIndex].name\n          ",modifiers:{"hover":true,"top":true}}],staticClass:"status-bar",style:({
            backgroundColor:
              _vm.config.groups[context.indexConfig.groupIndex].color,
          })}),_c('div',{staticClass:"entries"},_vm._l((context.value),function(entry,index){return _c('div',{key:entry.insertedIndex},[_c('Item',{attrs:{"entry-index":index,"entry":entry,"active":_vm.findActiveValues(context, entry),"context":context,"highlight":_vm.highlight},on:{"change-service-provider-name":_vm.changeServiceProviderName,"open-context-menu":function($event){return _vm.openContextMenu.apply(void 0, $event)}}})],1)}),0)]):_c('div')]}}])}),_c('ContextMenu',{ref:"context-menu",on:{"handle-status":_vm.handleStatus,"handle-post-edit":_vm.handlePostEdit}}),_c('PostEditName',{ref:"post-edit-name",on:{"change-name":_vm.changeServiceProviderName}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }