import moment from 'moment'
import confirmPopup from '@/utility/scripts/confirm'

export default async function wannaApprove() {
  const weekStart = moment(this.date).startOf('week').format('DD.MM.YYYY')
  const weekEnd = moment(this.date).endOf('week').format('DD.MM.YYYY')

  const { token } = this.$route.params

  try {
    const wannaTransmit = await confirmPopup(this, {
      okText: this.$t('StaffScheduling.StatusAction.approved'),
      title: this.$t('StaffScheduling.ApproveStaffScheduling'),
      text: this.$t('StaffScheduling.ApproveStaffSchedulingText', {
        weekStart,
        weekEnd,
      }),
    })

    if (!wannaTransmit) return

    if (this.updatePool.length !== 0) {
      const choice = await confirmPopup(this, {
        okText: this.$t('Global.Save'),
        title: this.$t('Global.Save'),
        text: this.$t('StaffScheduling.UnsavedChangesText'),
      })

      if (!choice) return

      await this.updateCalendar()
    }

    const { data } = await this.$axios.post(
      `staff-scheduling/service-provider-access/approve/${
        this.content[this.activeLayerIndex].layer.id
      }/${token}`,
    )

    if (data.length === 0) {
      this.activeLayerIndex = null
    }

    this.content = data

    this.$alert.success(
      this.$t('StaffScheduling.ApproveTextSuccess', {
        weekStart,
        weekEnd,
      }),
    )
  } catch (error) {
    this.$alert.error()
  }
}
