import moment from 'moment'
import {
  StaffSchedulingEntryReason,
  StaffSchedulingEntryType,
  StaffSchedulingType,
} from '../enums'

export function reasonToColor(reason) {
  switch (reason) {
    case StaffSchedulingEntryReason.systemInactiveServiceProviderCanceled:
    case StaffSchedulingEntryReason.systemInactiveEmployeeCanceled:
    case StaffSchedulingEntryReason.adminRemovedLayerEmployeeCanceled:
    case StaffSchedulingEntryReason.adminRemovedLayerServiceProviderCanceled:
    case StaffSchedulingEntryReason.adminServiceProviderCanceled:
    case StaffSchedulingEntryReason.employeeCanceled:
      return 'danger'
    case StaffSchedulingEntryReason.employeeRejected:
    case StaffSchedulingEntryReason.employeeAvailabilityRejected:
    case StaffSchedulingEntryReason.employeeNowUnavailable:
    case StaffSchedulingEntryReason.adminServiceProviderRejected:
      return 'danger'
    case StaffSchedulingEntryReason.serviceProviderApproved:
    case StaffSchedulingEntryReason.serviceProviderApprovedWeek:
    case StaffSchedulingEntryReason.employeeApproved:
    case StaffSchedulingEntryReason.employeeCancellationNoted:
      return 'success'
    case StaffSchedulingEntryReason.serviceProviderChangedName:
      return 'warning'
    case StaffSchedulingEntryReason.employeeAvailabilityDeleted:
    case StaffSchedulingEntryReason.systemInactiveEmployeeDeleted:
    case StaffSchedulingEntryReason.systemInactiveServiceProviderDeleted:
    case StaffSchedulingEntryReason.adminRemovedLayerEmployeeDeleted:
    case StaffSchedulingEntryReason.adminRemovedLayerServiceProviderDeleted:
      return 'secondary'
    default:
      return null
  }
}

export function getSubject(context) {
  if (!context) return this.$t('StaffScheduling.Unknown')

  if (context.type === StaffSchedulingType.Employee) {
    const employee = this.employeeDictionary[context.employee]

    if (!employee) return this.$t('StaffScheduling.Unknown')

    return `${employee.firstname} ${employee.lastname}`
  }

  const { name } = this.serviceProviderDictionary[context.serviceProvider]
  return name
}

export function titleExtended(trace) {
  if (trace.reason === StaffSchedulingEntryReason.serviceProviderApprovedWeek) {
    return `(${moment(parseInt(trace.context, 10)).format('DD.MM.YYYY')})`
  }

  if (!trace.staffScheduling) {
    return ''
  }

  const { date, layer, layerIndex } = trace.staffScheduling

  const { name } = this.layersDictionary[layer].configuration.layers[layerIndex]
  const momentDate = moment(date, 'YYYY-MM-DD')

  return `(${momentDate.format('DD.MM.YYYY')}, ${name})`
}

export function titleExtendedTooltip(trace) {
  if (!trace.staffScheduling) return 'Unknown'

  const layer = this.layers.find(x => x.id === trace.staffScheduling.layer)

  if (!layer) return 'Unknown'

  return layer.area
}

export function toActionText(trace) {
  if (trace.reason === StaffSchedulingEntryReason.serviceProviderApprovedWeek) {
    return this.$t('StaffScheduling.ActionTextWeekly', {
      subject: this.getSubject(trace.staffScheduling),
    })
  }

  if (trace.reason === StaffSchedulingEntryReason.employeeNowUnavailable) {
    return this.$t('StaffScheduling.NowUnavailable')
  }

  if (trace.type === StaffSchedulingEntryType.cancellationNoted) {
    return this.$t('StaffScheduling.CancellationNotedAction', {
      subject: this.getSubject(trace.staffScheduling),
    })
  }

  const getType = type => {
    switch (type) {
      case StaffSchedulingEntryType.approved:
        return 'Status.approved'
      case StaffSchedulingEntryType.rejected:
        return 'Status.rejected'
      case StaffSchedulingEntryType.canceled:
        return 'Status.canceled'
      case StaffSchedulingEntryType.deleted:
        return 'Deleted'
      case StaffSchedulingEntryType.edited:
        return 'Edited'
      default:
        return null
    }
  }

  return this.$t('StaffScheduling.ActionText', {
    subject: this.getSubject(trace.staffScheduling),
    type: this.$t(`StaffScheduling.${getType(trace.type)}`).toLowerCase(),
  })
}

export function toActionContext(trace) {
  return this.$t(`StaffScheduling.Reason.${trace.reason}`)
}
