<template>
    <span>
      {{ getMode() }} Mode - v{{ version }}
    </span>
</template>

<script>
import packageJson from '../../../../package.json'

export default {
  name: 'AppVersion',

  data: () => ({
    version: packageJson.version }),

  methods: {
    getMode() {
      if (process.env.NODE_ENV === 'production') {
        return 'Production'
      }

      if (process.env.NODE_ENV === 'development') {
        return 'Development'
      }

      if (process.env.NODE_ENV === 'staging') {
        return 'Staging'
      }

      if (process.env.NODE_ENV === 'local') {
        return 'Local'
      }

      return 'Unknown'
    },
  },
}
</script>
