<template>
  <b-popover
    v-if="context"
    ref="popover"
    :target="target"
    :show.sync="popoverShow"
    :container="target"
    triggers="click"
    placement="auto"
    @shown="onShown"
  >
    <template v-slot:title>
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ $t('StaffScheduling.ChangeName') }}</span>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="close"
          variant="transparent"
          aria-label="Close"
          @click="handleClose"
        >
          <span class="d-inline-block text-white" aria-hidden="true">
            &times;
          </span>
        </b-button>
      </div>
    </template>

    <validation-observer ref="rules" tag="form" @submit.prevent="handleSubmit">
      <validation-provider
        #default="{ errors }"
        :name="$t('StaffScheduling.Name')"
        rules="required"
        tag="div"
        class="mb-1"
      >
        <b-form-input
          ref="name"
          v-model="name"
          size="sm"
          :state="errors.length > 0 ? false : null"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-secondary"
        class="mr-1"
        @click="handleClose"
      >
        {{ $t('Global.Retreat') }}
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="outline-primary"
        @click="handleSubmit"
      >
        {{ $t('Global.Ok') }}
      </b-button>
    </validation-observer>
  </b-popover>
</template>

<script>
import { BPopover, BButton, BFormInput } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'PostEditName',

  components: {
    BPopover,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    Ripple,
  },

  data: () => ({
    popoverShow: false,
    context: null,
    name: null,
    target: null,

    required,
  }),

  methods: {
    handleClose() {
      this.popoverShow = false
      this.context = null
      this.name = null
      this.target = null
    },

    async handleSubmit() {
      const valid = await this.$refs.rules.validate()

      if (valid === false) return

      this.$emit('change-name', {
        context: this.context.context,
        value: this.name,
        entry: this.context.entry,
      })
      this.handleClose()
    },

    show(context, target) {
      this.target = target
      this.context = context
      this.name = context.active.serviceProviderName
      this.popoverShow = true
    },

    onShown() {
      this.focusRef(this.$refs.name)
    },

    focusRef(ref) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const el = ref.$el || ref
          el.focus()
        })
      })
    },
  },
}
</script>
