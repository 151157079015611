var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isPast)?_c('div',{staticClass:"d-flex",class:( _obj = {}, _obj[_vm.active.status] = true, _obj ),on:{"contextmenu":function($event){return _vm.$emit('open-context-menu', [
      $event,
      {
        context: _vm.context,
        entry: _vm.entry,
      } ])}}},[_c('Status',{attrs:{"status":_vm.active.status}}),_c('span',{staticClass:"user-select-none count"},[_vm._v(_vm._s(_vm.entryIndex + 1)+".")]),(_vm.showInput)?_c('b-form-input',{staticClass:"drag-zone-empty transition-highlight",class:{
      highlight: _vm.active.id === _vm.highlight,
    },attrs:{"value":_vm.display(),"size":"sm","debounce":500},on:{"update":function($event){return _vm.$emit('change-service-provider-name', {
        value: $event || null,
        context: _vm.context,
        entry: _vm.entry,
      })}}}):_c('div',{staticClass:"d-flex align-items-center transition-highlight",class:( _obj$1 = {}, _obj$1[_vm.active.status] = true, _obj$1.highlight = _vm.active.id === _vm.highlight, _obj$1 )},[(_vm.active.serviceProviderName)?_c('span',[_vm._v(" "+_vm._s(_vm.active.serviceProviderName)+" ")]):_c('i',[_vm._v(_vm._s(_vm.$t('StaffScheduling.UnknownEmployee')))])])],1):_c('div',{staticClass:"d-flex min-height"},[_c('Status',{attrs:{"status":_vm.active.status}}),_c('span',{staticClass:"user-select-none count"},[_vm._v(_vm._s(_vm.entryIndex + 1)+".")]),_c('div',{staticClass:"d-flex align-items-center transition-highlight",class:( _obj$2 = {}, _obj$2[_vm.active.status] = true, _obj$2.highlight = _vm.active.id === _vm.highlight, _obj$2 )},[(_vm.active.serviceProviderName)?_c('span',[_vm._v(" "+_vm._s(_vm.active.serviceProviderName)+" ")]):_c('i',[_vm._v(_vm._s(_vm.$t('StaffScheduling.UnknownEmployee')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }