<template>
  <div class="d-flex justify-content-between align-items-center pr-2">
    <div class="d-flex align-items-center">
      <img class="logo" src="/logo.png" alt="Logo" />
      <div>
        <div class="service-provider-name">
          {{ serviceProvider.name }}
        </div>
        <div v-if="serviceProvider.contactPerson">
          {{ serviceProvider.contactPerson }}
        </div>
      </div>
      <div class="notification" @click="$emit('open-stack')">
        <feather-icon
          v-if="stack.length !== 0"
          :badge="stack.length"
          badge-classes="bg-primary"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </div>
    </div>
    <div class="d-flex align-items-center">
      <div class="mr-1">
        {{ $t('StaffScheduling.TokenExpires', expDateTime) }}
      </div>
      <feather-icon
        v-b-tooltip.hover.top="$t('StaffScheduling.TokenFrom', iatDateTime)"
        icon="ShieldIcon"
        size="32"
        class="text-success"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    stack: {
      type: Array,
      required: true,
    },
    serviceProvider: {
      type: Object,
      required: true,
    },
    exp: {
      type: Number,
      required: true,
    },
    iat: {
      type: Number,
      required: true,
    },
  },

  computed: {
    expDateTime() {
      const date = moment(this.exp * 1000)
      return {
        date: date.format('DD.MM.YYYY'),
        time: date.format('HH:mm:ss'),
      }
    },
    iatDateTime() {
      const date = moment(this.iat * 1000)
      return {
        date: date.format('DD.MM.YYYY'),
        time: date.format('HH:mm:ss'),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.logo {
  object-fit: contain;
  width: 35px;
  height: 35px;
  margin: 1rem;
}

.service-provider-name {
  font-size: 16px;
}

.notification {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
</style>
