<template>
  <div
    v-if="timestamp !== 0 && serviceProvider"
    class="wrapper min-h-screen d-flex flex-column"
  >
    <b-card no-body>
      <Header
        :service-provider="serviceProvider"
        :iat="iat"
        :exp="exp"
        :stack="stack"
        @open-stack="openStack"
      />
    </b-card>
    <div class="flex-1">
      <b-card>
        <b-tabs v-model="activeLayerIndex">
          <b-tab v-for="(layer, index) in layers" :key="index">
            <template v-slot:title>
              <div class="d-flex align-items-center">
                {{ layer.area }}
                <div
                  :class="[index === activeLayerIndex ? 'circle' : 'standard']"
                >
                  {{ getNumberOfEmployees(index) }}
                </div>
              </div>
            </template>
          </b-tab>
          <template #no-options>
            {{ $t('StaffScheduling.NoLayer') }}
          </template>
        </b-tabs>
        <Content
          v-if="activeLayerIndex !== null && content[activeLayerIndex]"
          :service-provider="serviceProvider"
          :content="content"
          :timestamp="timestamp"
          :active-layer-index="activeLayerIndex"
          :update-pool="updatePool"
          :highlight="highlight"
          @force-update="forceUpdate"
        />
        <div v-else class="text-center">
          {{ $t('StaffScheduling.NoLayerSelected') }}
        </div>
        <Bottom
          :can-update="updatePool.length !== 0"
          :content="content"
          :all-approved="allApproved"
          @update-calendar="updateCalendar"
          @approve-calendar="approveCalendar"
        />
      </b-card>
    </div>

    <Stack
      ref="stack"
      :can-update="updatePool.length !== 0"
      :update-calendar="updateCalendar"
      :stack="stack"
      :token="$route.params.token"
      :service-provider="serviceProvider"
      :layers="layers"
      @set-stack="stack = $event"
      @highlight="showHighlight"
      @reload-calendar="init"
    />

    <AppVersion />
  </div>
</template>

<script>
import { BCard, BTab, BTabs } from 'bootstrap-vue'

import AppVersion from '@/@core/layouts/components/AppVersion.vue'

import Bottom from './components/service-providers/bottom.vue'
import Content from './components/service-providers/content.vue'
import Header from './components/service-providers/header.vue'
import Stack from './components/service-providers/stack.vue'

import { StaffSchedulingStatus } from './enums'
import approve from './utility/serviceProviderApprove'

export default {
  components: {
    Content,
    Header,
    BCard,
    Bottom,
    Stack,
    BTabs,
    BTab,
    AppVersion,
  },

  data: () => ({
    content: [],
    stack: [],
    serviceProvider: null,
    timestamp: 0,
    iat: 0,
    exp: 0,

    updatePool: [],

    activeLayerIndex: null,

    highlight: null,
    highlightTimeout: null,
  }),

  computed: {
    allApproved() {
      if (this.activeLayerIndex === null || this.content.length === 0) {
        return true
      }

      return this.content[this.activeLayerIndex].calendar.every(layerLine =>
        layerLine.every(date => {
          if (!date.value) return true
          return date.value.some(
            entry => entry.status === StaffSchedulingStatus.transmitted,
          )
        }),
      )
    },
    layers() {
      return this.content.map(x => x.layer)
    },
  },

  created() {
    this.init()
  },

  methods: {
    getNumberOfEmployees(index) {
      let numberOfEmployees = 0
      this.content[index].calendar.forEach(shift => {
        shift.forEach(weekDay => {
          numberOfEmployees += weekDay.value.length
        })
      })
      return numberOfEmployees
    },
    changeActiveLayer(value) {
      this.activeLayerIndex = value
    },
    async init() {
      this.activeLayerIndex = null
      this.content = []
      this.timestamp = 0

      const { token } = this.$route.params

      try {
        const { data } = await this.$axios.get(
          `staff-scheduling/service-provider-access/${token}`,
        )
        this.serviceProvider = data.serviceProvider
        this.content = data.content
        this.timestamp = data.timestamp
        this.iat = data.iat
        this.exp = data.exp
        this.stack = data.stack

        if (data.stack.length !== 0) {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.openStack()
            })
          })
        }

        if (data.content.length !== 0) {
          this.activeLayerIndex = 0
        }
      } catch (error) {
        this.$alert.error()
        this.$router.push({ name: 'token-invalid' })
      }
    },

    async showHighlight(trace) {
      if (this.highlightTimeout !== null) {
        clearTimeout(this.highlightTimeout)
      }

      this.highlight = trace.staffScheduling.id

      this.highlightTimeout = setTimeout(() => {
        this.highlight = null
      }, 5000)
    },

    forceUpdate() {
      this.content = JSON.parse(JSON.stringify(this.content))
    },

    async updateCalendar() {
      const { token } = this.$route.params

      try {
        const { data } = await this.$axios.post(
          `staff-scheduling/service-provider-access/${token}`,
          {
            updatePool: this.updatePool,
          },
        )
        if (data.length === 0) {
          this.activeLayerIndex = null
        }

        this.updatePool = []

        this.content = data
        this.$alert.success(
          this.$t('Global.SuccessUpdate', {
            subject: this.$t('StaffScheduling.Operations'),
          }),
        )
      } catch (error) {
        this.$alert.error()
      }
    },

    openStack() {
      this.$refs.stack.show = true
    },

    async approveCalendar() {
      return approve.call(this)
    },
  },
}
</script>

<style lang="scss" scoped>
.circle {
  border-radius: 50%;
  background: var(--primary);
  color: white;
}

.circle,
.standard {
  margin-left: 8px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  font-size: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  padding: 2rem 2rem 1rem 2rem;
}
</style>
