import moment from 'moment'

import Contracts, {
  ContactLayersToWork,
} from '@/views/loggedin/management/employees/utility/contracts'

import {
  AvailabilityStatus,
  StaffSchedulingStatus,
  StaffSchedulingType,
} from '../enums'
import { getsShiftsForWorkTimeCheck } from './checkWorkTime'
import { employeeNeedsRest, maxShiftsPopup, notAvailablePopup } from './popups'

function findWorkTimeBundle({ date, employee: id }) {
  for (let i = 0; i < this.employees.length; i += 1) {
    const employee = this.employees[i]

    for (let j = 0; j < employee.workTimeBundle.length; j += 1) {
      const bundle = employee.workTimeBundle[j]
      if (employee.employee.id === id && bundle.dates.includes(date)) {
        return bundle
      }
    }
  }

  return undefined
}

export function display(context, entry) {
  const active = this.findActiveValues(context, entry)

  if (active.type === StaffSchedulingType.Employee) {
    const workTimeBundle = this.employees.find(
      x => x.employee.id === active.employee,
    )
    if (!workTimeBundle) return this.$t('StaffScheduling.Unknown')

    return `${workTimeBundle.employee.firstname} ${workTimeBundle.employee.lastname}`
  }

  const serviceProvider = this.serviceProviders.find(
    x => x.id === active.serviceProvider,
  )
  return active.serviceProviderName || serviceProvider.name
}

export function findActiveValues(context, entry) {
  const updatePool = this.updatePool.find(x => x.id === entry.id)
  if (updatePool) return updatePool
  const createPoolIndex = this.findIndexInCreatePool(context, entry)
  const createPool = this.createPool[createPoolIndex]
  if (createPool) return createPool

  const deletePool = this.deletePool.find(x => x === entry.id)
  if (deletePool) {
    return {
      date: entry.date,
      layer: entry.layer,
      layerIndex: entry.layerIndex,
      status: null,
      type: null,
      employee: null,
      serviceProvider: null,
      serviceProviderName: null,
      insertedIndex: entry.insertedIndex,
    }
  }

  return entry
}

export function displayStatus(context, entry) {
  const active = this.findActiveValues(context, entry)
  if (active.type === null) return null
  return active.status
}

export function checkAvailability(context, dragContextReceive) {
  const dragContext = this.dragContext ?? dragContextReceive

  if (!dragContext) return AvailabilityStatus.unavailable

  if (dragContext.type === StaffSchedulingType.ServiceProvider) {
    return AvailabilityStatus.available
  }

  const av = this.availability.find(
    x =>
      x.layer === this.activeLayerPopulated.id &&
      x.index === context.indexConfig.layerIndex &&
      x.employee === dragContext.value.id &&
      x.date === context.date,
  )

  if (!av) return AvailabilityStatus.unavailable

  return av.isPrioritized
    ? AvailabilityStatus.prioritized
    : AvailabilityStatus.available
}

export async function checkRestTime(context, entry, employeeId) {
  try {
    const { data } = await this.$axios.post(
      `shift-calendar/check-rest-time/${employeeId}/${this.activeLayerPopulated.id}/${context.date}/${entry.layerIndex}`,
      {
        createPool: this.createPool,
        updatePool: this.updatePool,
        deletePool: this.deletePool,
      },
    )
    return data
  } catch {
    this.$alert.error()
    return true
  }
}

export function findIndexInCreatePool(context, entry) {
  return this.createPool.findIndex(
    x =>
      x.layer === this.activeLayerPopulated.id &&
      x.layerIndex === context.indexConfig.layerIndex &&
      x.date === context.date &&
      x.insertedIndex === entry.insertedIndex,
  )
}

export function displayContent(context, entry) {
  const active = this.findActiveValues(context, entry)
  if (active.type === null) return null
  return this.display(context, active)
}

export function adjustmentWorkTime(updateArray) {
  this.$emit('update-work-time', updateArray)
}

export async function dropCheck(employee, bundle) {
  if (bundle.contract === Contracts.slidingZoneTariff) return true

  const maxValue = ContactLayersToWork[bundle.contract].value

  const futureValue =
    bundle.workTime + (this.workTimeAdjustments[bundle.key] ?? 0) + 1

  if (futureValue > maxValue) {
    return maxShiftsPopup.call(this, {
      employee: `${employee.firstname} ${employee.lastname}`,
      timeUnit: this.$t(
        `Dates.Units.${ContactLayersToWork[bundle.contract].per}`,
      ),
    })
  }

  return true
}

export async function handleDrop(context, entry) {
  const dragContext = JSON.parse(JSON.stringify(this.dragContext))

  if (StaffSchedulingType.Employee === dragContext.type) {
    const workTimeCollection = this.employees.find(
      x => x.employee.id === dragContext.value.id,
    )

    const bundle = findWorkTimeBundle.call(this, {
      date: context.date,
      employee: dragContext.value.id,
    })

    const hasToRest = await this.checkRestTime(
      context,
      entry,
      dragContext.value.id,
    )

    if (hasToRest) {
      employeeNeedsRest.call(
        this,
        `${workTimeCollection.employee.firstname} ${workTimeCollection.employee.lastname}`,
      )
      return
    }

    if (
      this.checkAvailability(context, dragContext) ===
      AvailabilityStatus.unavailable
    ) {
      const askAvailable = await notAvailablePopup.call(
        this,
        `${workTimeCollection.employee.firstname} ${workTimeCollection.employee.lastname}`,
      )
      if (!askAvailable) return
    }

    const canContinue = await this.dropCheck(
      workTimeCollection.employee,
      bundle,
    )
    if (!canContinue) return
  }

  const content = {
    date: context.date,
    layer: this.activeLayerPopulated.id,
    layerIndex: context.indexConfig.layerIndex,
    insertedIndex: entry.insertedIndex,
    employee:
      StaffSchedulingType.Employee === dragContext.type
        ? dragContext.value.id
        : null,
    serviceProvider:
      StaffSchedulingType.ServiceProvider === dragContext.type
        ? dragContext.value.id
        : null,
    status: StaffSchedulingStatus.open,
    type: dragContext.type,
    serviceProviderName: null,
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

function findWorkTimeBundleKey(payload) {
  const bundle = findWorkTimeBundle.call(this, payload)
  if (bundle) return bundle.key
  return undefined
}

export function insertContent(context, fixedEntry, content) {
  const entry = this.findActiveValues(context, fixedEntry)
  if (entry.id) {
    const updatePoolIndex = this.updatePool.findIndex(x => x.id === entry.id)
    const updateContent = {
      ...content,
      id: entry.id,
    }

    if (updatePoolIndex !== -1) {
      this.updatePool[updatePoolIndex] = updateContent

      if (this.updatePool[updatePoolIndex].employee !== content.employee) {
        this.adjustmentWorkTime([
          {
            value: -1,
            key: findWorkTimeBundleKey.call(
              this,
              this.updatePool[updatePoolIndex],
            ),
          },
          {
            value: 1,
            key: findWorkTimeBundleKey.call(this, content),
          },
        ])
      }
      return
    }

    if (entry.employee !== content.employee) {
      this.adjustmentWorkTime([
        { value: -1, key: findWorkTimeBundleKey.call(this, entry) },
        { value: 1, key: findWorkTimeBundleKey.call(this, content) },
      ])
    }
    this.updatePool.push(updateContent)
    return
  }

  const createPoolIndex = this.findIndexInCreatePool(context, entry)

  if (createPoolIndex !== -1) {
    const createPool = this.createPool[createPoolIndex]
    if (createPool.employee !== content.employee) {
      this.adjustmentWorkTime([
        {
          value: -1,
          key: findWorkTimeBundleKey.call(
            this,
            this.createPool[createPoolIndex],
          ),
        },
        { value: 1, key: findWorkTimeBundleKey.call(this, content) },
      ])
    }
    this.createPool[createPoolIndex] = content
    return
  }

  if (content.type === StaffSchedulingType.Employee) {
    this.adjustmentWorkTime([
      { value: 1, key: findWorkTimeBundleKey.call(this, content) },
    ])
  }

  this.createPool.push(content)
}

export function checkRestTimeSync(context, entry, dragContext) {
  if (!dragContext || dragContext.type !== StaffSchedulingType.Employee) {
    return false
  }

  const employee = dragContext.value.id

  const selfCheck = context.value.some(selfEntry => {
    const active = this.findActiveValues(context, selfEntry)
    return active.employee === employee
  })

  if (selfCheck) return true

  const shiftsToCheck = getsShiftsForWorkTimeCheck.call(this, context)

  const currentCw = moment(context.date, 'YYYY-MM-DD').isoWeek()

  return shiftsToCheck.some(shiftConfig => {
    const momentDate = moment(shiftConfig.date, 'YYYY-MM-DD')

    if (currentCw !== momentDate.isoWeek()) return false

    const shiftContext =
      this.calendar[shiftConfig.layerIndex][momentDate.isoWeekday() - 1]

    return shiftContext.value.some(shiftEntry => {
      const active = this.findActiveValues(shiftContext, shiftEntry)
      return active.employee === employee
    })
  })
}

export function remove({ entry, context, active }) {
  const activeEntry = active || this.findActiveValues(context, entry)
  if (activeEntry.id) {
    this.deletePool.push(activeEntry.id)
    const updateIndex = this.updatePool.findIndex(x => x.id === activeEntry.id)
    if (updateIndex !== -1) {
      this.adjustmentWorkTime([
        {
          value: -1,
          key: findWorkTimeBundleKey.call(this, this.updatePool[updateIndex]),
        },
      ])
      this.updatePool.splice(updateIndex, 1)
      return
    }

    this.adjustmentWorkTime([
      { value: -1, key: findWorkTimeBundleKey.call(this, activeEntry) },
    ])
    return
  }

  const createIndex = this.findIndexInCreatePool(context, activeEntry)
  this.adjustmentWorkTime([
    {
      value: -1,
      key: findWorkTimeBundleKey.call(this, this.createPool[createIndex]),
    },
  ])
  this.createPool.splice(createIndex, 1)
}

export function handleStatus(event) {
  const { context, entry, active } = event.context

  const activeEntry = active || this.findActiveValues(context, entry)
  const content = {
    ...activeEntry,
    status: event.status,
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

export function updateAttendance(event) {
  const { context, entry, active } = event.context
  const activeEntry = active || this.findActiveValues(context, entry)
  const content = {
    ...activeEntry,
    attendance: event.attendance,
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

export function changeNote(event) {
  const { context, entry, active } = event.context
  const activeEntry = active || this.findActiveValues(context, entry)
  const content = {
    ...activeEntry,
    note: event.note === '' ? null : event.note,
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

export function changeTime(event) {
  const { context, entry, active } = event.context

  const activeEntry = active || this.findActiveValues(context, entry)

  const content = {
    ...activeEntry,
    [event.key]: event.time,
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

export function changeServiceProviderName({ entry, context, value }) {
  const activeEntry = this.findActiveValues(context, entry)
  const content = {
    ...activeEntry,
    serviceProviderName: value,
  }

  if (!value && content.status === StaffSchedulingStatus.approved) {
    content.status = StaffSchedulingStatus.transmitted
  }

  this.insertContent(context, entry, content)
  this.$emit('force-update')
}

export function fillRest(serviceProvider) {
  const today = moment()

  this.calendar.forEach(layerRow => {
    layerRow.forEach(context => {
      const date = moment(context.date, 'YYYY-MM-DD')
      if (date.isBefore(today, 'date')) return

      if (!context.value) return

      context.value.forEach(entry => {
        const activeValues = this.findActiveValues(context, entry)

        if (activeValues.type !== null) return

        const content = {
          date: context.date,
          layer: this.activeLayerPopulated.id,
          layerIndex: context.indexConfig.layerIndex,
          insertedIndex: entry.insertedIndex,
          employee: null,
          serviceProvider,
          status: StaffSchedulingStatus.open,
          type: StaffSchedulingType.ServiceProvider,
        }

        this.insertContent(context, entry, content)
      })
    })
  })
}
