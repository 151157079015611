<template>
  <vue-context ref="menu">
    <li v-if="show.approve">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('approved')"
      >
        <feather-icon icon="CheckIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.approved') }}
        </span>
      </b-link>
    </li>
    <li v-if="show.cancellationNoted">
      <b-link
        class="d-flex align-items-center"
        @click="handleStatus('cancellationNoted')"
      >
        <feather-icon icon="SlashIcon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.StatusAction.cancellationNoted') }}
        </span>
      </b-link>
    </li>

    <li v-if="show.postEdit">
      <b-link
        ref="post-edit-link"
        class="d-flex align-items-center"
        @click="handlePostEdit"
      >
        <feather-icon icon="Edit3Icon" size="16" />
        <span class="ml-75">
          {{ $t('StaffScheduling.ChangeName') }}
        </span>
      </b-link>
    </li>
  </vue-context>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import VueContext from 'vue-context'
import { StaffSchedulingStatus } from '../../enums'

export default {
  components: {
    VueContext,
    BLink,
  },

  data: () => ({
    context: null,
    event: null,
  }),

  computed: {
    show() {
      if (this.context === null) return {}

      const { status } = this.context.active

      return {
        approve: status === StaffSchedulingStatus.transmitted,
        cancellationNoted: status === StaffSchedulingStatus.canceled,
        postEdit: status === StaffSchedulingStatus.approved,
      }
    },
  },

  methods: {
    open(event, value) {
      this.context = value
      this.event = event
      this.$refs.menu.open(event)
    },

    handleStatus(status) {
      this.$emit('handle-status', {
        context: this.context,
        status,
      })
    },

    handlePostEdit() {
      this.$emit('handle-post-edit', {
        context: this.context,
        event: this.event,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/@core/scss/vue/libs/vue-context.scss';
</style>
