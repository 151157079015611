<template>
  <div class="calendar">
    <div class="calendar-row border-bottom">
      <div />

      <div
        v-for="moment in weekSpan"
        :key="moment.valueOf()"
        class="calendar-header-item"
      >
        {{ $t(`Dates.Week.Short.${moment.locale('en').format('dddd')}`) }}
        {{ moment.format('DD.MM.') }}
      </div>
    </div>

    <div
      v-for="(row, rowIndex) in calendar"
      :key="`calendar-row-${rowIndex}`"
      class="calendar-row border-bottom"
    >
      <div class="center-horizontal">
        <slot name="frontRow" :row-index="rowIndex">
          {{ rowIndex }}
        </slot>
      </div>
      <div
        v-for="(dateItem, dateIndex) in row"
        :key="`calendar-row-${rowIndex}-${dateIndex}`"
        class="border-left"
      >
        <slot :context="dateItem">
          {{ dateItem }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    date: {
      type: Number,
      required: true,
    },
    calendar: {
      type: Array,
      required: true,
    },
  },

  computed: {
    weekSpan() {
      const date = moment(this.date)
      const weekStart = date.clone().startOf('week')

      const days = []
      for (let i = 0; i <= 6; i += 1) {
        days.push(moment(weekStart).add(i, 'days'))
      }
      return days
    },
  },
}
</script>
<style lang="scss" scoped>
.calendar {
  .calendar-header-item {
    font-weight: 600;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-row {
    display: grid;
    grid-template-columns: 130px repeat(7, 1fr);
    grid-template-rows: auto;

    .center-horizontal {
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 0.7rem;
    }
  }
}
</style>
