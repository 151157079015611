export async function maxShiftsPopup({ employee, timeUnit }) {
  try {
    const messageVNode = this.$createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: this.$t('StaffScheduling.MaxShiftsText', {
          employee,
          timeUnit,
        }),
        style: 'font-size: 16px',
      },
    })

    return this.$bvModal.msgBoxConfirm([messageVNode], {
      title: this.$t('StaffScheduling.MaxShiftsTitle'),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: this.$t('Global.Next'),
      cancelTitle: this.$t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
  } catch {
    return false
  }
}

export async function notAvailablePopup(employee) {
  try {
    const messageVNode = this.$createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: this.$t('StaffScheduling.EmployeeNotAvailableText', {
          employee,
        }),
        style: 'font-size: 16px',
      },
    })

    return this.$bvModal.msgBoxConfirm([messageVNode], {
      title: this.$t('StaffScheduling.EmployeeNotAvailableTitle'),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: this.$t('Global.Next'),
      cancelTitle: this.$t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
  } catch {
    return false
  }
}

export async function employeeNeedsRest(employee) {
  try {
    const messageVNode = this.$createElement('div', {
      class: ['p-1'],
      domProps: {
        innerHTML: this.$t('StaffScheduling.EmployeeNeedsRestText', {
          employee,
        }),
        style: 'font-size: 16px',
      },
    })

    return this.$bvModal.msgBoxOk([messageVNode], {
      title: this.$t('StaffScheduling.EmployeeNeedsRestTitle'),
      size: 'sm',
      okVariant: 'danger',
      okTitle: this.$t('Global.Ok'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-2',
    })
  } catch {
    return false
  }
}
