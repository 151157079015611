<template>
  <b-modal
    v-model="show"
    :title="$t('StaffScheduling.UpToDateModalTitle')"
    size="lg"
    @ok="acknowledgeAll"
  >
    <AppTimeline>
      <TraceTimelineItem
        v-for="trace in sorted"
        :key="trace.id"
        :time="getExecuted(trace)"
        :title="toActionText(trace)"
        :title-extended-tooltip="titleExtendedTooltip(trace)"
        :title-extended="titleExtended(trace)"
        :subtitle="toActionContext(trace)"
        :variant="reasonToColor(trace.reason)"
        @click="acknowledge(trace)"
      />
    </AppTimeline>
    <template #modal-footer="{ ok }">
      <div class="d-flex align-items-center justify-content-between w-100">
        <b-form-checkbox
          v-if="validToDelete.length !== 0"
          v-model="acknowledgeFlag"
          :value="true"
          :unchecked-value="false"
        >
          {{
            $tc('StaffScheduling.ReadAllAndAcknowledge', validToDelete.length, {
              count: validToDelete.length,
            })
          }}
        </b-form-checkbox>
        <div v-else />
        <b-button variant="primary" @click="ok">
          {{ $t('Global.ReadAll') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BButton, BFormCheckbox, BModal } from 'bootstrap-vue'
import moment from 'moment'

import confirmPopup from '@/utility/scripts/confirm'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'

import { StaffSchedulingEntryType, StaffSchedulingStatus } from '../../enums'
import {
  reasonToColor,
  titleExtended,
  titleExtendedTooltip,
  toActionContext,
  toActionText,
} from '../../utility/stack'
import TraceTimelineItem from '../stack/components/traceTimelineItem.vue'

export default {
  components: {
    BModal,
    BButton,
    BFormCheckbox,
    AppTimeline,
    TraceTimelineItem,
  },

  props: {
    serviceProvider: {
      type: Object,
      required: true,
    },
    stack: {
      type: Array,
      required: true,
    },
    layers: {
      type: Array,
      required: true,
    },
    canUpdate: {
      type: Boolean,
      required: true,
    },
    updateCalendar: {
      type: Function,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    show: false,
    acknowledgeFlag: false,
  }),

  computed: {
    sorted() {
      return this.stack
        .map(x => ({
          ...x,
          timestamp: new Date(x.executed).getTime(),
        }))
        .sort((a, b) => b.timestamp - a.timestamp)
    },
    validToDelete() {
      return this.stack
        .filter(x => x.staffScheduling)
        .filter(
          x => x.staffScheduling.status === StaffSchedulingStatus.canceled,
        )
        .map(x => x.staffScheduling.id)
    },
    getExecuted() {
      const today = moment.now()
      return ({ executed }) =>
        moment.duration(new Date(executed).getTime() - today).humanize(true)
    },
    layersDictionary() {
      return this.layers.reduce((prev, curr) => {
        prev[curr.id] = curr
        return prev
      }, {})
    },
  },

  methods: {
    async acknowledgeAll(e) {
      try {
        e.preventDefault()

        const { data } = await this.$axios.post(
          `staff-scheduling/service-provider-access/staff-scheduling-queue/${this.token}`,
          {
            ids: this.stack.map(x => x.id),
          },
        )

        this.$emit('set-stack', data)

        if (this.acknowledgeFlag === true) {
          if (this.validToDelete.length !== 0) {
            if (this.canUpdate) {
              const choice = await confirmPopup(this, {
                okText: this.$t('Global.Save'),
                title: this.$t('Global.Save'),
                text: this.$t('StaffScheduling.UnsavedChangesText'),
              })

              if (!choice) return

              await this.updateCalendar()
            }

            await this.$axios.post(
              `staff-scheduling/service-provider-access/staff-scheduling/acknowledge-many/${this.token}`,
              {
                ids: this.validToDelete,
              },
            )
            this.$emit('reload-calendar')
          }
        }

        if (data.length === 0) {
          this.show = false
        }
      } catch {
        this.$alert.error()
      }
    },

    async acknowledge(trace) {
      if (trace.type !== StaffSchedulingEntryType.deleted) {
        this.$emit('highlight', trace)
      }

      this.$emit(
        'set-stack',
        this.stack.filter(x => x.id !== trace.id),
      )

      this.show = false

      try {
        await this.$axios.delete(
          `staff-scheduling/service-provider-access/staff-scheduling-queue/${trace.id}/${this.token}`,
        )
        this.$emit(
          'set-stack',
          this.stack.filter(x => x.id !== trace.id),
        )
      } catch {
        this.$alert.error()
      }
    },
    getSubject(context) {
      return context.serviceProviderName || this.serviceProvider.name
    },
    reasonToColor(...args) {
      return reasonToColor.call(this, ...args)
    },
    toActionContext(...args) {
      return toActionContext.call(this, ...args)
    },
    toActionText(...args) {
      return toActionText.call(this, ...args)
    },
    titleExtended(...args) {
      return titleExtended.call(this, ...args)
    },
    titleExtendedTooltip(...args) {
      return titleExtendedTooltip.call(this, ...args)
    },
  },
}
</script>
