import moment from 'moment'

const HOURS_TO_REST = 11

const MS_TO_REST = HOURS_TO_REST * 60 * 60 * 1000

export const dateStringToWeekIndex = dateString => {
  const date = new Date(dateString)
  const weekday = date.getDay()
  return (weekday + 6) % 7
}

const timeStringToMs = timeString => {
  const [hourString, minuteString] = timeString.split(':')
  return (Number(hourString) * 60 + Number(minuteString)) * 60 * 1000
}

function nextShift(dateString, layerIndex) {
  const date = moment(dateString, 'YYYY-MM-DD')

  const layersLength = this.config.layers.length

  if (layerIndex + 1 === layersLength) {
    date.add(1, 'day')
  }

  const nextLayerIndex = (layerIndex + 1) % layersLength

  return {
    date: date.format('YYYY-MM-DD'),
    layerIndex: nextLayerIndex,
  }
}

function previousShift(dateString, layerIndex) {
  const date = moment(dateString, 'YYYY-MM-DD')
  const layersLength = this.config.layers.length

  if (layerIndex - 1 === -1) {
    date.subtract(1, 'day')
  }

  const nextLayerIndex = (layerIndex - 1 + layersLength) % layersLength

  return {
    date: date.format('YYYY-MM-DD'),
    layerIndex: nextLayerIndex,
  }
}

function isOverNight(layerIndex) {
  return (
    timeStringToMs(this.config.layers[layerIndex].startTime) >=
    timeStringToMs(this.config.layers[layerIndex].endTime)
  )
}

function checkFutureWorkTime(context) {
  let shiftConfig = {
    date: context.date,
    layerIndex: context.indexConfig.layerIndex,
  }

  const currentShiftEndMoment = moment(
    `${shiftConfig.date} ${this.config.layers[shiftConfig.layerIndex].endTime}`,
    'YYYY-MM-DD HH:mm',
  )

  if (isOverNight.call(this, shiftConfig.layerIndex)) {
    currentShiftEndMoment.add(1, 'day')
  }

  const currentShiftEnd = currentShiftEndMoment.valueOf()

  let unixCheckedFuture = currentShiftEnd

  const shiftConfigPool = []

  do {
    shiftConfig = nextShift.call(this, shiftConfig.date, shiftConfig.layerIndex)

    const nextShiftEnd = moment(
      `${shiftConfig.date} ${
        this.config.layers[shiftConfig.layerIndex].endTime
      }`,
      'YYYY-MM-DD HH:mm',
    )

    if (isOverNight.call(this, shiftConfig.layerIndex)) {
      nextShiftEnd.add(1, 'day')
    }

    shiftConfigPool.push({ ...shiftConfig })

    unixCheckedFuture = nextShiftEnd.valueOf()
  } while (unixCheckedFuture < currentShiftEnd + MS_TO_REST)

  return shiftConfigPool
}

function checkPastWorkTime(context) {
  let shiftConfig = {
    date: context.date,
    layerIndex: context.indexConfig.layerIndex,
  }

  const currentShiftStartTime = moment(
    `${shiftConfig.date} ${
      this.config.layers[shiftConfig.layerIndex].startTime
    }`,
    'YYYY-MM-DD HH:mm',
  ).valueOf()

  let unixCheckedPast = currentShiftStartTime

  const shiftConfigPool = []

  do {
    shiftConfig = previousShift.call(
      this,
      shiftConfig.date,
      shiftConfig.layerIndex,
    )

    shiftConfigPool.push({ ...shiftConfig })

    const previousShiftStart = moment(
      `${shiftConfig.date} ${
        this.config.layers[shiftConfig.layerIndex].startTime
      }`,
      'YYYY-MM-DD HH:mm',
    ).valueOf()

    unixCheckedPast = previousShiftStart
  } while (unixCheckedPast > currentShiftStartTime - MS_TO_REST)

  return shiftConfigPool
}

export function getsShiftsForWorkTimeCheck(context) {
  return [
    checkFutureWorkTime.call(this, context),
    checkPastWorkTime.call(this, context),
  ].flat()
}
